
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {keys, isEmpty} from 'lodash-es'
import {media} from '../../../utils'
import {ContentContainer} from '../../base'
import Button from '../Button/Button'


const StyledContentContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
`

const StyledServicesMapContainer = styled.div`
  display: flex;
  position: relative;
  width: 57rem;
  height: auto;

  ${media.down('mobile')(css`
    width: 28rem;
  `)}
`

const StyledMap = styled.div`
  svg {
    width: 57rem;
    height: auto;

    ${media.down('mobile')(css`
      width: 28rem;
    `)}
  }
`

const StyledPoint = styled.div`
  position: absolute;
  top: ${({top}) => top}%;
  left: ${({left}) => left}%;
  transform: translateX(-50%) translateY(-100%);
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.secondary};
  cursor: pointer;
  width: 6px;
  height: 6px;
  content: '';

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 50%;
    padding: 14px;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 50%;
    padding: 8px;
    content: '';
  }

  &:hover,
  &:focus {
    opacity: 0.5;
    background-color: ${({theme}) => theme.colors.primary};

    &::before,
    &::after {
      border: 1px solid ${({theme}) => theme.colors.primary};
    }
  }

  ${({isActive}) => isActive && css`
    background-color: ${({theme}) => theme.colors.primary};

    &::before,
    &::after {
      border: 1px solid ${({theme}) => theme.colors.primary};
    }
  `}

  ${media.down('mobile')(css`
    transform: scale(0.5);
  `)}
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledButton = styled(Button)`
  margin: 3rem 1rem 0;
  border: 1px solid  ${({theme}) => theme.colors.primary};

  &:hover {
    background-color: ${({theme}) => theme.colors.primary};
  }

  ${media.down('mobile')(css`
    margin: 5rem 1rem 0;
  `)}

  ${({isActive}) => isActive && css`
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.textInverted};
  `}
`

const ServicesMaps = ({activeCountry, buttonTitle, countries, setActiveCountry, activeCity, slideTo}) => (
  <StyledContentContainer id="predajne-miesta">
    <If condition={activeCountry?.branchCountries?.map}>
      <StyledServicesMapContainer>
        <StyledMap dangerouslySetInnerHTML={{__html: activeCountry.branchCountries.map}} />
        <If condition={activeCountry?.branches?.nodes}>
          <For each="poi" of={activeCountry.branches.nodes} index="idx">
            <StyledPoint
                key={idx}
                top={poi.poi.yPosition}
                left={poi.poi.xPosition}
                isActive={activeCity === poi.poi.city}
                onClick={() => slideTo(poi.poi.city)}
            />
          </For>
        </If>
      </StyledServicesMapContainer>
    </If>
    <StyledButtonContainer>
      <For each="country" of={keys(countries)}>
        <If condition={!isEmpty(countries[country]?.branchCountries?.map) && !isEmpty(buttonTitle)}>
          <StyledButton
              key={country}
              onClick={() => {
                setActiveCountry(countries[country])
              }}
              isActive={activeCountry.name === country}
          >
            {buttonTitle} {country}
          </StyledButton>
        </If>
      </For>
    </StyledButtonContainer>
  </StyledContentContainer>
)

ServicesMaps.propTypes = {
  activeCountry: PropTypes.object,
  buttonTitle: PropTypes.string,
  countries: PropTypes.object,
  setActiveCountry: PropTypes.func,
  activeCity: PropTypes.string,
  slideTo: PropTypes.func,
}

export default ServicesMaps
