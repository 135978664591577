
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import theme from '../../../theme/theme'
import {contactMapIcon, contactMapText, media} from '../../../utils'
import BaseLink from '../../base/BaseLink/BaseLink'
import {Typography} from '../../base'
import Slider from '../Slider/lazy'
import SSRSuspense from '../SSRSuspense/SSRSuspense'


const StyledContactIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.down('mobile')(css`
    align-items: center;
  `)}
`

const StyledLink = styled(BaseLink)`
  display: flex;
  align-self: center;
  margin: 0.25rem 0;
  width: max-content;
  color: ${({theme}) => theme.colors.textInverted};

  ${({$dark}) => $dark && css`
    color: ${({theme}) => theme.colors.secondary};
  `}

  span {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  }

  svg {
    margin-right: 0.75rem;
    width: 2rem;
    height: 2rem;
  }
`

const StyledText = styled.p`
  padding-top: 0.5rem;
`

const StyledSliderContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
`

const StyledSlideContent = styled.div`
  border: 1px solid ${({theme}) => theme.colors.secondary};
  padding: 1.75rem 0;
  text-align: center;
`

const StyledCity = styled(Typography)`
  margin-bottom: 1.75rem;
`

const StyledAdress = styled(Typography)`
  margin-bottom: 1.75rem;
  line-height: 1.5;
`

const StyledTable = styled.table`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`

const StyledTableHeader = styled.th`
  color: ${({theme}) => theme.colors.primary};
`

const StyledTableData = styled.td`
  padding: 0 0 1rem 1.5rem;
`

const ServicesSlider = ({activeCountry, control, setControl}) => (
  <If condition={activeCountry?.branches?.nodes}>
    <StyledSliderContainer>
      <SSRSuspense fallback={<div />}>
        <Slider
            breakpoints={{
              [theme.global.media.mobile]: {
                slidesPerView: 4,
              },
            }}
            $fullWidth={false}
            disableEffect
            mode="dark"
            arrowMobilePosition="down"
            control={control}
            onChange={setControl}
            spaceBetween={20}
            slidesPerView={1}
            centeredSlides
        >
          <For each="branch" of={activeCountry?.branches?.nodes} index="idx">
            <StyledSlideContent key={idx}>
              <StyledCity type="h2" isHeading>{branch.poi.city}</StyledCity>
              <StyledAdress>{branch.poi.address}</StyledAdress>
              <If condition={!isEmpty(branch.poi.openingHours)}>
                <StyledTable>
                  <tbody>
                    <For each="openingHour" of={branch.poi.openingHours} index="idx">
                      <tr key={`tr-${idx}`}>
                        <StyledTableHeader>{openingHour?.text}</StyledTableHeader>
                        <StyledTableData colSpan={isEmpty(openingHour?.afternoon) && 2}>
                          {openingHour?.morning}
                        </StyledTableData>
                        <StyledTableData>{openingHour?.afternoon}</StyledTableData>
                      </tr>
                    </For>
                  </tbody>
                </StyledTable>
              </If>
              <If condition={!isEmpty(branch.poi.contacts)}>
                <StyledContactIcons $dark>
                  <For each="contact" of={branch.poi.contacts} index="idx">
                    <StyledLink
                        $dark
                        to={contactMapText[contact.type] + contact.text}
                        key={`${contact.text}-${idx}`}
                    >
                      {contactMapIcon[contact.type]}
                      <StyledText>{contact.text}</StyledText>
                    </StyledLink>
                  </For>
                </StyledContactIcons>
              </If>
            </StyledSlideContent>
          </For>
        </Slider>
      </SSRSuspense>
    </StyledSliderContainer>
  </If>
)

ServicesSlider.propTypes = {
  activeCountry: PropTypes.object.isRequired,
  control: PropTypes.object,
  setControl: PropTypes.func,
}

export default ServicesSlider
