import {useState} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import styled, {css} from 'styled-components'
import {isEmpty, keyBy, findIndex} from 'lodash-es'
import {getLangToSlug, media} from '../utils'
import {MailIcon, PhoneIcon} from '../assets/icons'
import {AppShell, CounterList, Hero} from '../components/custom'
import BaseLink from '../components/base/BaseLink/BaseLink'
import {ContentContainer, Typography} from '../components/base'
import ContactContainer from '../components/custom/Contact/ContactContainer'
import ServicesMaps from '../components/custom/Contact/ServicesMaps'
import ServicesSlider from '../components/custom/Contact/ServicesSlider'


const StyledMapContainer = styled.div`
  iframe {
    min-width: 100%;
    height: 34.625rem;
  }
`

const StyledPosition = styled(Typography)`
  margin: 1rem 0 1.5rem;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.gray};
`

const StyledContactIconsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledContactIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5.5rem;


  ${({$dark}) => $dark && css`
    height: 2rem;
  `}

  ${media.down('mobile')(css`
    align-items: center;
    height: 4.75rem;
  `)}
`

const StyledLink = styled(BaseLink)`
  display: flex;
  align-self: center;
  width: max-content;
  color: ${({theme}) => theme.colors.textInverted};

  ${({$dark}) => $dark && css`
    color: ${({theme}) => theme.colors.secondary};
  `}

  span {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  }

  svg {
    margin-right: 0.75rem;
    width: 2rem;
    height: 2rem;
  }
`

const StyledText = styled.p`
  padding-top: 0.5rem;
`

const Contact = ({data, pageContext: {lang}}) => {
  const {title, featuredImage, content, seo, translations, uri, template: {contact}} = data?.wpPage
  const [control, setControl] = useState(null)
  const [activeCountry, setActiveCountry] = useState(contact?.branchesCountry?.[0] || null)
  const [activeCity, setActiveCity] = useState(activeCountry?.branches?.nodes &&
    activeCountry?.branches?.nodes[0]?.poi?.city)
  const slideTo = (setCity) => {
    const cityIndex = findIndex(activeCountry?.branches?.nodes, {poi: {city: setCity}})
    setActiveCity(setCity)
    control.slideToLoop(cityIndex)
  }

  const countries = keyBy(contact.branchesCountry, 'name')

  return (
    <AppShell title={title} seo={seo} lang={lang} langToSlug={getLangToSlug({translations, uri, lang})}>
      <Hero
          url={featuredImage?.node?.localFile?.url}
          to="#predajne-miesta"
          title={title}
          buttonTitle={contact?.pointOfSalesText}
      />
      <If condition={content}>
        <ContactContainer content={content} />
      </If>
      <If condition={!isEmpty(contact?.googleMapsEmbed)}>
        <StyledMapContainer>
          <div dangerouslySetInnerHTML={{__html: contact.googleMapsEmbed}} />
        </StyledMapContainer>
      </If>
      <If condition={contact?.managers}>
        <CounterList>
          <For each="manager" of={contact?.managers} index="idx">
            <CounterList.Item
                key={idx}
                index={idx}
                count={manager.shortcode}
                description={
                  <>
                    <Typography type="h2" isHeading>
                      {manager.name}
                    </Typography>
                    <StyledPosition thin type="h4">
                      {manager.position}
                    </StyledPosition>
                    <If condition={!isEmpty(manager?.telephoneNumber) && !isEmpty(manager?.email)}>
                      <StyledContactIconsContainer>
                        <StyledContactIcons>
                          <If condition={!isEmpty(manager?.telephoneNumber)}>
                            <StyledLink to={`tel: ${manager.telephoneNumber}`} isExternal>
                              <span className="sr-only">Ikona telefónu</span>
                              <PhoneIcon />
                              <StyledText>{manager.telephoneNumber}</StyledText>
                            </StyledLink>
                          </If>
                          <If condition={!isEmpty(manager?.email)}>
                            <StyledLink to={`mailto: ${manager.email}`} isExternal>
                              <span className="sr-only">Ikona e-mailu</span>
                              <MailIcon />
                              <StyledText>{manager.email}</StyledText>
                            </StyledLink>
                          </If>
                        </StyledContactIcons>
                      </StyledContactIconsContainer>
                    </If>
                  </>
                }
            />
          </For>
        </CounterList>
      </If>
      <If condition={!isEmpty(countries)}>
        <ServicesMaps
            activeCountry={activeCountry}
            countries={countries}
            setActiveCountry={setActiveCountry}
            activeCity={activeCity}
            slideTo={slideTo}
            buttonTitle={contact.buttonBranches}
        />
      </If>
      <If condition={!isEmpty(activeCountry)}>
        <ContentContainer compact>
          <ServicesSlider activeCountry={activeCountry} control={control} setControl={setControl} />
        </ContentContainer>
      </If>
    </AppShell>
  )
}

export const data = graphql`
  query ContactPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      translations {
        locale
        href
      }
      seo {
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          mediaItemUrl
        }
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          localFile {
            url
          }
        }
      }
      content
      template {
        ... on WpTemplate_Kontakt {
          templateName
          contact {
            googleMapsEmbed
            pointOfSalesText
            buttonBranches
            managers {
              shortcode
              name
              position
              telephoneNumber
              email
            }
            branchesCountry {
              name
              branchCountries {
                map
              }
              branches {
                nodes {
                  poi {
                    address
                    city
                    xPosition
                    yPosition
                    contacts {
                      text
                      type
                    }
                    openingHours {
                      afternoon
                      morning
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default Contact
