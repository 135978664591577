
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import {ContentContainer, Typography} from '../../base'


const StyledContactsContainer = styled(Typography)`
  .wp-block-columns {
    display: flex;

    ${media.down('mobile')(css`
      text-align: center;
      flex-direction: column;
    `)}
  }

  .wp-block-column {
    flex: 1;
    margin-top: 3rem;
  }

  h2 {
    position: relative;
    margin-bottom: 3rem;
    line-height: 1;
    font-family: ${({theme}) => theme.components.typography.fonts.heading};
    font-size: ${({theme}) => theme.components.typography.variants.h2};

    &::before {
      position: absolute;
      bottom: -3rem;
      border-bottom: 3px solid ${({theme}) => theme.colors.primary};
      width: 2rem;
      content: '';

      ${media.down('mobile')(css`
        margin: auto;
        left: 0;
        right: 0;
      `)}
    }
  }

  p {
    line-height: 2;
    font-family: ${({theme}) => theme.components.typography.fonts.text};
    font-size: ${({theme}) => theme.components.typography.variants.p};
  }
`

const ContactContainer = ({content}) => {
  return (
    <ContentContainer>
      <StyledContactsContainer type="div" dangerouslySetInnerHTML={{__html: content}} />
    </ContentContainer>
  )
}

ContactContainer.propTypes = {
  content: PropTypes.string.isRequired,
}

export default ContactContainer
